import { useState } from 'react';
import {
    Toolbar,
    Box,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
    Switch,
} from '@mui/material';
import { useAppContext } from 'hooks';

interface Props {
    filters: Record<string, any>;
    query?: string;
    onApply: (filters: Record<string, any>) => void;
}

const defaultFilters = {
    sort: 'updated_at:desc',
    priceSort: '',
    selectedSuppliers: [],
    available: true,
    lastQuery: '',
    firstSearch: true,
    country: '',
}

export const Filters = ({ filters, query = '', onApply }: Props) => {

    const [_filters, setFilters] = useState<Record<string, any>>(filters);

    const { suppliers } = useAppContext();

    const handleSortChange = (e: any) => {
        const result: Record<string, any> = { sort: e.target.value }
        if (e.target.value.includes('price:')) result.priceSort = '';
        setFilters({  ..._filters, ...result });
      }
    
    const handlePriceSortChange = (e: any) => {
        setFilters({ ..._filters, priceSort: e.target.value });
    }

    const handleCountryChange = (e: any) => {
        setFilters({ ..._filters, country: e.target.value });
    }

    const handleAvailableChange = (e: any) => {
        setFilters({ ...filters, available: e.target.checked });
    }

    const handleSelectSupplier = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const id = e?.target.name;
        const result = _filters.selectedSuppliers;
        if (!id) return;
        if (!result.includes(id)) {
          result.push(id);
        } else {
          const index = result.findIndex((item: string) => item === id);
          if (index >= 0) result.splice(index, 1);
        }
        setFilters({ ...filters, selectedSuppliers: result });
    };

    const applyFilters = () => onApply(_filters);
    const clearFilters = () => onApply(defaultFilters);

    return (
        <>
        <Toolbar>
            <Grid 
                container 
                direction="row" 
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Button onClick={clearFilters}>Clear</Button>
                </Grid>
                <Grid item>
                    <Button onClick={applyFilters}>Apply Filters</Button>
                </Grid>
            </Grid>
        </Toolbar>
        <Box margin={2}>
            <Box mb={5}>
              <FormControlLabel control={<Switch checked={_filters.available} onChange={handleAvailableChange}/>} label="Show only in-stock items"/>
            </Box>
        <Stack direction="column" spacing={3}>
          <FormControl fullWidth>
            <InputLabel id="sort-label">Sort Results</InputLabel>
            <Select
              labelId="sort-label"
              id="sort"
              value={_filters?.sort}
              label="Sort Results"
              onChange={handleSortChange}
            >
              <MenuItem value={'_text_match:desc'} disabled={!query}>Most Relevant</MenuItem>
              <MenuItem value={'updated_at:desc'}>Most Recent</MenuItem>
              <MenuItem value={'updated_at:asc'}>Oldest</MenuItem>
              <MenuItem value={'price:asc'}>Price: Lowest First</MenuItem>
              <MenuItem value={'price:desc'}>Price: Highest First</MenuItem>
              <MenuItem value={'rating:desc'}>Rating: Highest to Lowest</MenuItem>
              <MenuItem value={'rating:asc'}>Rating: Lowest to Highest</MenuItem>
              <MenuItem value={'name:asc'}>Name: A - Z</MenuItem>
              <MenuItem value={'name:desc'}>Name: Z - A</MenuItem>
              <MenuItem value={'source_name:asc'}>Supplier: A - Z</MenuItem>
              <MenuItem value={'source_name:desc'}>Supplier: Z - A</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="price-sort-label">Price</InputLabel>
            <Select
              labelId="price-sort-label"
              id="vsort"
              value={_filters.priceSort}
              label="Price"
              onChange={handlePriceSortChange}
              disabled={_filters.sort.includes('price:')}
            >
              <MenuItem value={''}>No preference</MenuItem>
              <MenuItem value={'price:asc'}>Price: Lowest to Highest</MenuItem>
              <MenuItem value={'price:desc'}>Price: Highest to Lowest</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="country-sort-label">Country</InputLabel>
            <Select
              labelId="country-sort-label"
              id="country-sort"
              value={_filters.country}
              label="Country"
              onChange={handleCountryChange}
            >
              <MenuItem value={''}>No preference</MenuItem>
              <MenuItem value={'US'}>US</MenuItem>
              <MenuItem value={'AU'}>Australia</MenuItem>
              <MenuItem value={'CA'}>Canada</MenuItem>
              <MenuItem value={'GB'}>UK</MenuItem>
            </Select>
          </FormControl> 
          <InputLabel id="supplier-label">Supplier(s)</InputLabel>
          <FormControl fullWidth>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              {suppliers?.filter((s: any) => !_filters.country || s.country === _filters.country).map((s: any) => {
                  //const f = facets.find((f: any) => f.value === s.id);
                  const checked = (_filters?.selectedSuppliers || []).findIndex((item: string) => item === s.id) >= 0;
                  return (
                    <FormControlLabel 
                      key={s.id}
                      label={`${s.name}${s.country ? ` (${s.country})`: ''}`}
                      name={s.id}
                      control={<Checkbox checked={checked} onChange={handleSelectSupplier} />}
                    />
                  )
              })}
            </Box>
          </FormControl>
        </Stack>
        </Box>
        </>
    )
}

