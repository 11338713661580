import { Typography } from "@mui/material";

export const P = ({ children }: { children: any }) => <Typography paragraph={true}>{children}</Typography>

export const Bold = ({ children }: { children: any }) => <span style={{ fontWeight: 'bold' }}>{children}</span>

export const Italic = ({ children }: { children: any }) => <span style={{ fontStyle: 'italic' }}>{children}</span>

export const BoldItalic = ({ children }: { children: any }) => <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{children}</span>

export const Monospace = ({ children }: { children: any }) => <span style={{ fontFamily: 'Monospace' }}>{children}</span>