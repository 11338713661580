import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Money } from 'components';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface Props {
  product: Record<string, any>,
  onRemove: (product: Record<string, any>) => void,
}

export const CompareBox = ({ product, onRemove = () => {}}: Props) => 
    <Grid display="flex" xs={12} md={2} direction="column" justifyContent="space-between" sx={{border: '1px solid rgba(0,0,0,.12)', borderRadius: '10px'}}>
      <Grid item textAlign="center" sx={{overflow: 'hidden'}}>
      <a href={product.url} target="_new">
        <img src={product.image} style={{ 
          height: '100px',
          maxWidth: '100%',
          margin: '0 auto',
          borderRadius: '10px',
        }}/>
      </a>
    </Grid>
    <Grid item textAlign="center">
      <Typography variant="caption" display="block" sx={{ textTransform: 'uppercase' }}>
        {product.name}
      </Typography>
    </Grid>
    <Grid item textAlign="center">
      <Typography variant="caption" display="block">
        @<Link
            underline="none" 
            href={product.url} 
            target="_new"
        >
            {product.source_name}
        </Link>
      </Typography>
    </Grid>
    <Grid item textAlign="center">
      <Typography paragraph={true}>
        <Money value={product.price}/> {product.currency && <span>{product.currency}</span>} {!product.available && (<p style={{ color:'red', fontWeight:'bold'}}>SOLD OUT</p>)}
      </Typography>
    </Grid>
    <Grid item
      sx={{
        flexGrow: 1,
        verticalAlign: 'bottom',
        alignItems: 'flex-end',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
      }}
    >
      <Link 
          color="inherit"
          sx={{cursor:"pointer"}}
          underline="none"
          target="_new"
          onClick={() => onRemove(product)}
      >
          <Typography variant="caption" display="block">
            <RemoveCircleOutlineIcon fontSize="inherit" sx={{verticalAlign:'text-top'}}/> Remove
          </Typography>
      </Link>
    </Grid>      
  </Grid>