import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { Home, About, FAQ, Contact, Instructions } from './Default';

export const ROUTES = {
    home: '/',
    about: '/about',
    faq: '/faq',
    contact: '/contact',
    instructions: '/instructions',
}

export const InternalRoutes = () => (
    <Router>
        <Routes>
            <Route path={ROUTES.home} element={<Home/>} />
            <Route path={ROUTES.about} element={<About/>} />
            <Route path={ROUTES.faq} element={<FAQ/>} />
            <Route path={ROUTES.contact} element={<Contact/>} />
            <Route path={ROUTES.instructions} element={<Instructions/>} />
        </Routes>
    </Router>
)