import Typesense, { Client } from "typesense"

let client: Client;

export const getSearchClient = () => {

    if (!client) {
        client = new Typesense.Client({
            'nodes': [{
                'host': process.env.REACT_APP_SEARCH_SERVER || '',
                'port': 443,
                'protocol': 'https'
            }],
            'apiKey': process.env.REACT_APP_SEARCH_API_KEY || '',
            'connectionTimeoutSeconds': 2
        });
    }

    return client;
}