import isEqual from 'lodash/isEqual';
import extend from 'lodash/extend';

export const reducer = (
  currentState: Record<string, any>,
  payload: Record<string, any>,
) => {
  // Objects are the same, just return current state
  if (isEqual(currentState, payload)) {
    return currentState;
  }
  return extend({}, currentState, payload);
};
