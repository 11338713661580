import {Money} from '.';
import {
    Box,
    Grid,
    Typography,
    Link,
    Button
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {DateTime} from 'luxon';
import {useAppContext} from 'hooks';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const LastUpdate = ({ value }:{ value: number | undefined }) => {
    if (!value) return <span>unknown</span>;
    const lastUpdate = DateTime.fromMillis(value);
    const diff = DateTime.utc().diff(lastUpdate, ['days','hours','minutes','seconds']);
    let result = '';
    if (diff.days) {
        result += `${diff.days} day${diff.days > 1 ? 's' : ''}`;
    }
    if (diff.hours) {
        if (diff.days) result += ', ';
        result += `${diff.hours} hour${diff.hours > 1 ? 's' : ''}`;
    }
    if (diff.minutes) {
        if (diff.hours) result += ', ';
        result += `${diff.minutes} min${diff.minutes > 1 ? 's' : ''}`;
    }
    const secs = Math.round(diff.seconds || 0);
    if (secs > 0) {
        if (diff.minutes) result += ', ';
        result += `${secs} secs`;
    } else if (!diff.days && !diff.hours && !diff.minutes) {
        result = 'just a few seconds';
    }
    result += ' ago';
    return (
        <span>
            {result}
        </span>
    )
}

export const ProductBox = ({ 
    product,
    onSimilar = () => {},
    onCompare = () => {},
    canCompare = true,
}:{ 
    product: Record<string, any>,
    onSimilar?: (text: string) => void,
    onCompare?: (product: Record<string, any>) => void,
    canCompare?: boolean,
}) => {

    const { matchesSmall } = useAppContext();

    const {
        url = '',
        image = '', // add default no-image here
        price = 0,
        name = '',
        source_name = '',
        source_category = '',
        source_category_url = '',
        updated_at = 0,
        rating = 0,
        available,
        currency,
        instructions,
    } = product || {};

    if (!price || !name) return null;

    return (
        <Grid 
            display="flex" 
            direction={matchesSmall ? "column" : "row"}  
            xs={12}
            spacing={2}
        >
            <Grid 
                item 
                xs={12} 
                sm={3}
                lg={2}
                sx={{
                    textAlign: matchesSmall ? 'center' : 'left',
                    background: matchesSmall ? 'none' : `url(${image || '/img/no_image.png'}) no-repeat center`,
                    backgroundSize: 'contain',
                }}
            >
                <a href={url} target="_new" style={{display:'block', height: '100%', width:'100%', textAlign:'center'}}>
                    {matchesSmall && (
                        <img src={image || '/img/no_image.png'} style={{ 
                            maxHeight: '180px',
                            maxWidth: '180px',
                            margin: '0 auto 10px auto',
                        }}/>
                    )}
                </a>
            </Grid>
            <Grid 
                item
                xs={12}
                sm={8} 
                sx={{ 
                    ml: { 
                        xs: 'none', md: 1 
                    },
                    pl: {
                        xs: 'none',
                        sm: 3,
                    }
                }
            }>
                <Typography variant="h6">
                    <Link 
                        color="inherit"
                        underline="none"
                        href={url} 
                        target="_new"
                    >
                        {name}
                    </Link>
                </Typography>
                <Box>
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{paddingRight: '7px'}}>
                            {(source_category_url && source_category) && (
                             <span>
                                Found in <Link underline="none" href={source_category_url} target="_new">{source_category}</Link>
                             </span>
                            )}
                            @<Link
                                underline="none" 
                                href={url} 
                                target="_new"
                            >
                                {source_name}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        <Money value={price} currency={currency}/> {!available && (<span style={{marginLeft:'10px', color:'red', fontWeight:'bold'}}>SOLD OUT</span>)}
                    </Typography>
                </Box>
                { updated_at > 0 && (
                    <Box mt={2}>
                        <Typography sx={{fontStyle: 'italic'}} variant="caption">
                            Last Updated <LastUpdate value={updated_at}/>
                        </Typography>
                    </Box>
                )}
                <Box>
                    <Grid container direction="row">
                        <Grid item>
                            <Button 
                                size="small"
                                onClick={() => onSimilar(name)}
                            >
                                View Similar
                            </Button>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item>
                            {rating > 0 && <StarIcon color="warning" sx={{lineHeight: 1}}/>}
                        </Grid>
                        <Grid item>
                            {rating > 0 && <>{rating}</>}
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item>
                            <Button 
                                size="small"
                                onClick={() => onCompare(product)}
                                disabled={!canCompare}
                            >
                                Compare
                            </Button>
                        </Grid>
                        {(instructions && instructions.length > 0) && (
                            <>
                                <Grid item xs={1}/>
                                <Grid item>
                                    <Link target="_new" href={instructions[0].url} underline="none"><PictureAsPdfIcon sx={{lineHeight: 1, verticalAlign: 'bottom'}}/> Instructions</Link>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )

}