import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import {InternalRoutes} from 'routes';
import {SearchProvider, AppProvider, FirebaseProvider} from 'providers';
import Box from '@mui/material/Box';

export default function App() {
  return (
    <FirebaseProvider>
      <ThemeProvider theme={theme}>
        <SearchProvider>
          <AppProvider>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <InternalRoutes/>
            </Box>
          </AppProvider>
        </SearchProvider>
      </ThemeProvider>
    </FirebaseProvider>
  );
}