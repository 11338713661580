import { useState } from 'react';
import { withBaseLayout } from 'hoc';
import { Typography, Container } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';
import { P, AppName } from 'components';

import { useFirebaseContext } from 'hooks';

export const Contact = withBaseLayout(() => {

    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState('');
    const [success, setSuccess] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);

    const { callable } = useFirebaseContext();

    const send = async () => {

        const name = (document.getElementById('name') as HTMLInputElement);
        const subject = (document.getElementById('subject') as HTMLInputElement);
        const message = (document.getElementById('message') as HTMLInputElement);
        const email = (document.getElementById('email') as HTMLInputElement);

        if (!email?.value || !message?.value) {
            setStatus('Both email address and message are required.');
            setSuccess(false);
            setOpen(true);
            return;
        }

        setSending(true);
        const { data } = await callable('contact', {
            name: name.value,
            subject: subject.value,
            message: message.value,
            email: email.value,
        });

        setSending(false);

        if (data?.success) {
            setStatus('Message sent!');
            setSuccess(true);
            name.value = '';
            subject.value = '';
            message.value = '';
            email.value = '';
        } else {
            setStatus('There was an error sending your message.');
            setSuccess(false);
        }

        setOpen(true);

    }

    return (
        <Container>
            <Typography sx={{ typography: { xs: 'h5', md: 'h3' }}} mb={5}>
                Contact Us
            </Typography>
            <Box>
                <P>We'd love to hear from you! If you have a suggestion or idea on how we could improve <AppName/> or would like to see a site indexed, please feel free to share it with us!</P>
            </Box>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, maxWidth: '75%' },
                }}
                noValidate
                autoComplete="off"
            >
                <Stack direction="column">
                    <TextField
                        id="name"
                        label="Your Name"
                        variant="standard"
                    />
                    <TextField
                        required
                        id="email"
                        label="Your Email"
                        variant="standard"
                        type="email"
                    />
                    <TextField
                        id="subject"
                        label="Subject"
                        variant="standard"
                    />
                    <TextField
                        required
                        id="message"
                        label="Your Message"
                        multiline
                        rows={6}
                        variant="standard"
                    />
                    <LoadingButton
                        loading={sending}
                        onClick={send}
                        endIcon={<SendIcon/>}
                        loadingPosition="center"
                        variant="contained"
                        sx={{maxWidth:'76%'}}
                    >
                        <span>Send Message</span>
                    </LoadingButton>
                </Stack>
            </Box>
            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity={success ? "success" : "error"} sx={{ width: '100%' }}>
                    {status}
                </Alert>
            </Snackbar>
        </Container>
    )

});