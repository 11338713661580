import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export const InstructionBox = ({ data } : { data: Record<string, any> }) => {

    return (
        <Grid container direction="row">
            <Grid item xs={12} sm={7}>
                <Link underline="hover" href={data.url} target="_new">{data.title}</Link>
            </Grid>
            <Grid item xs={12} sm={1} mt={{ xs: 1, sm: 'none' }}/>
            <Grid item xs={12} sm={4}>
                <Link underline="hover" href={data.source_url} target="_new">{data.source_name}</Link>
            </Grid>
        </Grid>
    )
    
}