import {Component} from 'react';
import {Grid, Box} from '@mui/material';
import {Footer, Suppliers, TopBar} from 'components';

export const withBaseLayout = (WrappedComponent: any) => {

  return class extends Component {
    render = () => (
      <TopBar>
        <Box id="mainContainer" height="100vh" width="100vw" display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
          <Grid id="pageGrid" container spacing={2} xs={12} direction="row" justifyContent="start" alignItems="flex-start" sx={{ mt: { xs: 7, md: 10 }}}>
            <Grid id="leftColumn" item xs={0} md={3} sx={{ display: { xs: 'none', md: 'block'}}}>
              <Box sx={{padding:'1em'}}>
                <Suppliers/>
              </Box>
            </Grid>
            <Grid id="rightColumn" item xs={12} md={7}>
              <WrappedComponent/>
            </Grid>
          </Grid>
          <Footer/>
        </Box>
      </TopBar>
    )
  };
  
};
