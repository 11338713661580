export const getBrowserLocales = (options = {}) => {

    const defaultOptions = {
      languageCodeOnly: false,
      countryCodeOnly: true,
    };
  
    const opt = {
      ...defaultOptions,
      ...options,
    };
  
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
  
    if (!browserLocales) {
      return undefined;
    }
  
    if (browserLocales[0] === 'en') return ['US'];
    
    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        :  opt.countryCodeOnly 
            ? trimmedLocale.match(/-|_/) ? trimmedLocale.split(/-|_/)[1] : 'XX'
            : trimmedLocale;
    }).filter(l => l !== 'XX');
  
  }