import {createContext} from 'react';
import {initializeApp, getApps} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getAuth, onAuthStateChanged, User, Auth} from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);

interface GenericObject { [key:string]: any };

export interface FirebaseContextProps {
  callable: (name: string, data?: GenericObject) => Promise<any>;
}

export const FirebaseContext = createContext<FirebaseContextProps | null>(null);

export const FirebaseProvider = (props: any) => {

  const callable = async (name: string, data?: Record<string, any>): Promise<any> => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data || {});
    } catch (error: any) {
      console.log(error);
      return {error: error.message};
    }
  };

  return (
    <FirebaseContext.Provider value={{callable}}>
      {getApps().length > 0 ? (
        props.children
      ) : (
        <></>
      )}
    </FirebaseContext.Provider>
  );
};
