import { useState, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import { 
    FormGroup,
    Input,
    InputAdornment,
    IconButton,
    Button,
} from '@mui/material';
import PageviewTwoToneIcon from '@mui/icons-material/PageviewTwoTone';
import { SearchTipsModal } from '.';

export const Searchbox = ({
    onSearch = (query: string) => {},
    altName = '',
    altUrl = '',
}:{
    onSearch: (query: string) => void;
    altName: string;
    altUrl: string;
}) => {

    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const el = useRef<HTMLInputElement>()

    const search = () => {
        onSearch(el.current?.value || '');
    }

    const keyDown = (e: any) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            search();
        }
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    return (
        <FormGroup
            row={true}
            sx={{
              width: '100%'
            }}
          >
            <Input 
              inputRef={el}
              fullWidth
              disableUnderline={true}
              id="search-query" 
              placeholder="Search for something..."
              onKeyDown={keyDown}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton 
                      onClick={search} 
                      disabled={el && (el.current?.value?.length || 0) < 3}
                    >
                      <PageviewTwoToneIcon fontSize="large"/>
                  </IconButton>
                </InputAdornment>
              }
              sx={{ 
                border: '2px solid',
                borderColor: '#c2c2c2',
                borderRadius: '7px',
                padding: '10px',
              }}
            />
            <div style={{ display: 'block', width: '100%' }}>
                <div style={{width:'49%', display:'inline-block'}}>
                    <Button onClick={() => navigate(altUrl)}>Search {altName}</Button>
                </div>
                <div style={{width:'49%', textAlign: 'right', display: 'inline-block'}}>
                    <Button onClick={toggleModal}>Search Help</Button>
                </div>
            </div>
            <SearchTipsModal open={modalOpen} onClose={toggleModal}/>
          </FormGroup>
    )
}