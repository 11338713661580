import {useAppContext} from 'hooks';
import {Box, Link, ListItem, ListItemText} from '@mui/material';
import List from '@mui/material/List';
import {Bold} from 'components';

type Country = {
    symbol: string;
    name: string;
}

const countries: Country[] = [{
    symbol: 'US',
    name: 'US',
},{
    symbol: 'CA',
    name: 'Canada',
},{
    symbol: 'GB',
    name: 'UK',
},{
    symbol: 'AU',
    name: 'Australia',
}];

export const Suppliers = () => {
    const { suppliers } = useAppContext();
    return (
        <Box>
            <List>
            {countries.map((country: Country) => {
                const sites = suppliers?.filter(s => s.country === country.symbol);
                return (
                    <>
                    <ListItem>
                        <ListItemText><Bold>{country.name}</Bold></ListItemText>
                    </ListItem>
                    <List>
                        {sites?.map(supplier => (
                            <ListItem>
                                <Link href={supplier.url} target="_new">
                                    {supplier.name}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                    </>
                )
            })}
            </List>
        </Box>
    )
}