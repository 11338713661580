import { useEffect, useReducer, useRef } from 'react';
import { ProductBox, Compare, SearchTipsModal, Filters, Searchbox } from 'components';
import { useSearchContext, useAppContext } from 'hooks';
import { withBaseLayout } from 'hoc';
import { reducer } from 'lib';
import cloneDeep from 'lodash/cloneDeep';
import { 
  Grid,
  FormGroup,
  Box,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Stack,
  Divider,
  Button,
  Drawer,
  FormControlLabel,
  Chip,
} from '@mui/material';
import type {SearchParams} from 'typesense/lib/Typesense/Documents';
import { getAnalytics, logEvent } from "firebase/analytics";
import {ROUTES} from 'routes';

const exclusions: Record<string, string[]> = {
  all: [
    'bushing', 
    'bushings', 
    'tube', 
    'tubes', 
    'portfolio', 
    'replacement', 
    'mandrel', 
    'mandrels', 
    'drill',
    'bit',
    'blank',
    'blanks',
    '"bag"',
    'reamer',
    'shaft',
    'trimmer',
    'sleeve',
    'sleeves',
    '"display case"', 
    '"pen case"',
    '"pencil case"',
    'disassembly',
    'stand',
    'nibs',
    '"pen ink"',
    'lube',
    'section',
    'pack',
    'refills',
    'pouch',
  ],
  US: [],
  AU: [],
  CA: [],
  GB: [
    'bushes'
  ]
};

export const Home = withBaseLayout(() => {

  const [{
    ready = false,
    found = 0,
    hits = [],
    sort = 'updated_at:desc',
    priceSort = '',
    page = 1,
    perPage = 25,
    filter = 'all',
    lastQuery = '',
    selections = [],
    available = false,
    firstSearch = true,
    modalOpen = false,
    drawerOpen = false,
    suppliersUpdated = null,
    country = 'US',
  }, dispatch] = useReducer(reducer, {});

  const { searchCollection } = useSearchContext();

  const { suppliers, matchesSmall, locale } = useAppContext();

  const analytics = getAnalytics();

  const drawerWidth = matchesSmall ? '90%' : 400;
  const drawerAnchor = 'right';

  const selectedSuppliers = useRef<string[]>([]);

  useEffect(() => {
    (async () => {
      if (!suppliers?.length || !searchCollection) return;
      dispatch({ ready: true });
    })()
  },[suppliers]);

  useEffect(() => {
    if (!ready) return;
    searchProducts();
  },[page]);

  useEffect(() => {
    if (!locale || locale === country) return;
    dispatch({ country: locale });
  },[locale]);

  useEffect(() => {
    if (!ready) return;
    if (page === 1) {
      searchProducts(); 
    } else {
      dispatch({ page: 1 });
    }
  },[perPage, sort, priceSort, filter, lastQuery, ready, available, suppliersUpdated, country]);

  const searchProducts = async () => {
    if (!ready) return;
    const q = firstSearch 
      ? `pen ${exclusions.all.concat(exclusions[locale || 'US']).map(s => `-${s}`).join(' ')}` 
      : lastQuery || '*';
    const params: SearchParams = {
      q,
      query_by: "name",
      facet_by: "source_id",
      max_facet_values: 99,
      per_page: perPage,
      page,
      filter_by: '',
      sort_by: '',
      prioritize_token_position: true,
    };
    params.sort_by = sort;
    if (priceSort) params.sort_by += `,${priceSort}`;
    const filters = firstSearch ? ['available:true'] : [];
    //if (filter && filter !== 'all') filters.push(`source_id:${filter}`);
    if (available) filters.push('available:true');
    if (sort.includes('rating')) {
      filters.push('rating:>0');
    }
    if (country) filters.push(`country:${country}`);
    if (selectedSuppliers.current?.length) {
      filters.push(`source_id:[${selectedSuppliers.current.join(',')}]`);
    }
    params.filter_by = filters.join('&&');

    if (!firstSearch) logEvent(analytics, 'search', { value: lastQuery });

    if (searchCollection) {
      let results = await searchCollection('products', params);
      const hits = results?.hits || [];
      dispatch({
        out_of: results?.out_of || 0,
        found: results?.found || 0,
        hits,
        facets: results?.facet_counts ? results.facet_counts[0].counts : [],
      });
      window.scrollTo(0, 0);
    }
  }

  const search = async (query: string) => {
    if (query?.length < 3 && filter === 'all') return;
    dispatch({ lastQuery: query, firstSearch: false });
  }

  const toggleModal = () => {
    dispatch({ modalOpen: !modalOpen });
  }

  const toggleDrawer = () => {
    dispatch({ drawerOpen: !drawerOpen });
  }

  const handlePageChange = (e: any, value: number) => {
    dispatch({ page: value });
  }

  const handlePerPageChange = (e: any) => {
    dispatch({ perPage : e.target.value });
  }

  const handleSimilar = (s: string) => {
    // remove generic keywords like pen, pencil and 
    // make sure to remove any parts that are numbers.
    const [part1, part2] = s.split(' ')
      .filter(s => !s.toLowerCase().match(/pen|pencil|jr|style|components|\-|\_/g)
         && parseInt(s.replace(/[^0-9]/g, '') || '0') < 1
      );
    const text = `${part1}${part2 ? ` ${part2}` : ''}`.trim();
    dispatch({ firstSearch: false, query: text.trim(), lastQuery: text.trim() });
  }

  const handleCompare = (product: Record<string, any>) => {
    logEvent(analytics, 'compare_product', { id: product.id, name: product.name, source: product.source_id })
    if (selections?.length > 4) return;
    const item = selections.find((p: Record<string, any>) => p.id === product.id);
    if (!item) selections.push(product);
    // mark highest and lowest prices ?
    dispatch({ selections: selections.sort((a: any, b: any) => a.price - b.price) });
  }

  const reset = () => {
    dispatch({ firstSearch: true, lastQuery: '', query: '', filter: 'all', page: 1, available: false });
  }

  const applyFilters = (f: Record<string, any>) => {
    const selected = f.selectedSuppliers || [];
    delete f.selectedSuppliers;
    const update = selected.length !== selectedSuppliers.current.length || 
                  (JSON.stringify(selected.sort((a: string, b: string) => a.localeCompare(b))) !== JSON.stringify(selectedSuppliers.current.sort((a: string, b: string) => a.localeCompare(b))))
    dispatch({
      ...f,
      drawerOpen: false,
      suppliersUpdated: update ? new Date() : null,
      firstSearch: false,
    });
    if (update) selectedSuppliers.current = selected;
  }

  const handleSupplierDelete = (id: string) => {
    const idx = selectedSuppliers?.current?.indexOf(id);
    if (idx >= 0) {
      selectedSuppliers.current.splice(idx, 1);
      dispatch({ suppliersUpdated: new Date() });
    }
  }

  const pages = found 
    ? Math.ceil(found / perPage)
    : 0;

  return (
    <Box ml={2} sx={{ mt: { xs: 3, lg: 1 }}}>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <Searchbox onSearch={search} altUrl={ROUTES.instructions} altName={'Instructions'}/>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} direction="row" mt={3}>
        <Grid item xs={12} md={5} sx={{textAlign: { xs: 'center', md: 'left' }, mb: { xs: '20px', md: 'inherit' }}}>
            {found > 0 && (
            <Box>
              <span style={{ fontWeight:'bolder'}}>{found}</span> products {/*(of <span style={{ fontWeight:'bolder'}}>{out_of}</span>) {lastQuery && <span>for "<span style={{ fontWeight:'bolder'}}>{lastQuery.replace(/"/g, '')}</span>"</span>}*/} found. 
              {lastQuery !== '' && <span style={{ marginLeft: '1em', cursor: 'pointer', textDecoration: 'underline' }} onClick={reset}>Reset</span>}
            </Box>
          )}
          {(lastQuery && found < 1) && (
            <p>No results were found for "<span style={{ fontWeight:'bolder'}}>{lastQuery}</span>". <span style={{ marginLeft: '1em', cursor: 'pointer', textDecoration: 'underline' }} onClick={reset}>Reset</span></p> 
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mr={4}>
            <Stack
                justifyContent={'flex-end'}
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' }}}/>}
            >
              <Button onClick={toggleDrawer}>Sort &amp; Filter</Button>
              {hits?.length > 0 && (
                <FormGroup>
                  <FormControlLabel 
                    labelPlacement='start'
                    label='Per Page: '
                    control={
                      <FormControl variant={'standard'}>
                        <Select
                          labelId="per-page-label"
                          id="per-page"
                          value={perPage}
                          onChange={handlePerPageChange}
                          sx={{ ml: { xs: 1, md: 2 }}}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    }
                  />
                </FormGroup>
              )}
              </Stack>
            </Box>
          </Grid>
      </Grid>
        <Compare selections={selections} onChange={(update) => dispatch({ selections: update })}/>
        <Divider sx={{marginBottom: '20px' }}/>
        {selectedSuppliers.current?.length > 0 && (
          <Box mb={3}>
              <Box pb={2}>
                {selectedSuppliers.current.map(s => {
                  const supplier = suppliers?.find(supplier => supplier.id === s);
                  return supplier 
                    ? <span style={{ display: 'inline-block', marginRight: '5px', marginBottom: '5px' }}><Chip label={supplier.name} color="primary" onDelete={() => handleSupplierDelete(s)}/></span>
                    : null
                })}
              </Box>
            <Divider/>
          </Box>
        )}
        <Stack 
          direction="column"
          spacing={2}
          divider={<Divider orientation="horizontal" />}
        >
          {hits.map((hit: any) => { 
            const item = selections?.length < 5 
              ? selections.find((p: Record<string, any>) => p.id === hit.document.id)
              : null;
            return (
              <ProductBox 
                key={hit.document.id} 
                product={hit.document} 
                canCompare={!item && selections?.length < 5} 
                onCompare={handleCompare} 
                onSimilar={handleSimilar}
              />
            )
          })}
        </Stack>
        {pages > 1 && (
          <Box textAlign="center" mt={5}>
            <Stack alignItems="center">
              <Pagination count={pages} variant="outlined" shape="rounded" onChange={handlePageChange}/>
            </Stack>
          </Box>
        )}
      <SearchTipsModal open={modalOpen} onClose={toggleModal}/>
      <Drawer
        open={drawerOpen}
        anchor={drawerAnchor}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Filters 
          query={lastQuery}
          filters={
            cloneDeep({ 
              sort, 
              priceSort, 
              selectedSuppliers: selectedSuppliers.current || [], 
              available,
              country, 
          })} 
          onApply={applyFilters}
        />
      </Drawer>
    </Box>
  );
});

export default Home;
