import {useState, useEffect, createContext} from 'react';
import {useSearchContext} from 'hooks';
import type {SearchParams} from 'typesense/lib/Typesense/Documents';
import { CircularProgress, Box } from '@mui/material';
import { getBrowserLocales } from 'lib';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IAppContext {
    suppliers: Record<string, any>[];
    locale: string,
    getSuppliers: () => Promise<Record<string, any> | null>,
    matchesSmall: boolean;
    matchesLarge: boolean;
}

export const AppContext = createContext<Partial<IAppContext>>({
    suppliers: [],
    locale: 'US',
    getSuppliers: async () => null,
    matchesSmall: false,
    matchesLarge: true,
});

export const AppProvider = (props: any) => {

    const [suppliers, setSuppliers] = useState<Record<string, any>[]>([]);
    const [locale, setLocale] = useState<string>('US');
    const [ready, setReady] = useState<boolean>(false);

    const { searchCollection } = useSearchContext();

    const matchesSmall = useMediaQuery('(max-width: 600px)');
    const matchesLarge = useMediaQuery('(min-width: 601px)');

    useEffect(() => {
        (async () => {
            // need better way
            const locales = getBrowserLocales() || ['US'];
            setLocale(locales[0]); 
            if (!suppliers?.length) await getSuppliers();
        })()
    },[]);

    useEffect(() => {
        if (!searchCollection) return;
        (async () => {
            if (!suppliers?.length) await getSuppliers();
        })()
    }, [searchCollection]);
    
    const getSuppliers = async (): Promise<Record<string, any> | null> => {
        if (!searchCollection) return null;
        const params: SearchParams = {
            q: '*',
            query_by: "name",
            per_page: 100,
            sort_by: "name:asc",
            page: 1,
        };
        let results = await searchCollection('sites', params);
        const hits = results?.hits || [];
        const s = [];
        for (const hit of hits) {
            s.push({
                id: hit.document.slug,
                name: hit.document.name,
                url: hit.document.url,
                country: hit.document.country,
            });
        }
        setSuppliers(s);
        setReady(true);
        return s;
    }

    if (!ready) return (
        <Box mt={10} textAlign="center">
            <CircularProgress/>
        </Box>
    )

    return (
        <AppContext.Provider value={{ suppliers, getSuppliers, locale, matchesSmall, matchesLarge }}>
            {props.children}
        </AppContext.Provider>
    )

}