import { getSearchClient } from 'lib';
import {useState, useEffect, createContext} from 'react';
import type {Client} from 'typesense';
import type {SearchParams} from 'typesense/lib/Typesense/Documents';

interface ISearchContext {
    client: Client;
    searchCollection: (collection: string, params: SearchParams) => Promise<any> | null;
}

export const SearchContext = createContext<Partial<ISearchContext>>({});

export const SearchProvider = (props: any) => {

    const [client, setClient] = useState<Client>();

    useEffect(() => {
        setClient(getSearchClient());
    },[]);

    const searchCollection = async (collection: string, params: SearchParams) => {
        if (!client) return null;
        try {
            let results = await client
                ?.collections(collection)
                .documents()
                .search(params);
            return results || null;
        } catch (err) {
            console.log(err);
        }
        return null;
    }

    return (
        <SearchContext.Provider value={{ 
            client,
            searchCollection,
        }}>
            {props.children}
        </SearchContext.Provider>
    )
}