import {useReducer, useEffect} from 'react';
import {withBaseLayout} from 'hoc';
import {Searchbox, InstructionBox, P} from 'components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import {ROUTES} from 'routes';
import {reducer} from 'lib';
import type {SearchParams} from 'typesense/lib/Typesense/Documents';
import { getAnalytics, logEvent } from "firebase/analytics";
import {useSearchContext} from 'hooks';
import { Typography } from '@mui/material';

export const Instructions = withBaseLayout(() => {

    const analytics = getAnalytics();

    const [{
        lastQuery = '',
        hits = [],
        found = 0,
        perPage = 200,
        page = 1,
        firstSearch = true,
    }, dispatch] = useReducer(reducer, {});

    const { searchCollection } = useSearchContext();

    useEffect(() => {
        searchProducts();
    }, [lastQuery, page]);

    const handlePageChange = (e: any, value: number) => {
        dispatch({ page: value });
    }

    const searchProducts = async () => {
        if (!lastQuery && !firstSearch) return;
        const params: SearchParams = {
          q: firstSearch ? '*' : lastQuery,
          query_by: "title",
          per_page: perPage,
          page,
          filter_by: '',
          sort_by: firstSearch ? 'title:asc' : '_text_match:desc',
          prioritize_token_position: true,
        };
    
        logEvent(analytics, 'instructions-search', { value: lastQuery });
    
        if (searchCollection) {
          let results = await searchCollection('instructions', params);
          const hits = results?.hits || [];
          dispatch({
            found: results?.found || 0,
            hits,
          });
          window.scrollTo(0, 0);
        }
    }

    const search = async (query: string) => {
        if (query?.length < 3) return;
        dispatch({ lastQuery: query, firstSearch: false });
    }
    
    const reset = () => {
        dispatch({ lastQuery: '', firstSearch: true });
    }

    const pages = found 
        ? Math.ceil(found / perPage)
        : 0;

    return (
        <Box ml={2} sx={{ mt: { xs: 3, lg: 1 }}}>
            <Typography variant="h4">
                Search Instructions
            </Typography>
            <P>Use the search form to look for assembly instructions by kit name.</P>
            <Grid container>
                <Grid item xs={12} lg={7}>
                    <Searchbox onSearch={search} altUrl={ROUTES.home} altName={'Pen Kits'}/>
                </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} direction="row">
                <Grid item mt={3} xs={12} md={5} sx={{textAlign: { xs: 'center', md: 'left' }, mb: { xs: '20px', md: 'inherit' }}}>
                    {found > 0 && (
                        <Box>
                            <span style={{ fontWeight:'bolder'}}>{found}</span> documents {/*(of <span style={{ fontWeight:'bolder'}}>{out_of}</span>) {lastQuery && <span>for "<span style={{ fontWeight:'bolder'}}>{lastQuery.replace(/"/g, '')}</span>"</span>}*/} found. 
                            {lastQuery !== '' && <span style={{ marginLeft: '1em', cursor: 'pointer', textDecoration: 'underline' }} onClick={reset}>Reset</span>}
                        </Box>
                    )}
                    {(lastQuery && found < 1) && (
                        <p>No results were found for "<span style={{ fontWeight:'bolder'}}>{lastQuery}</span>". <span style={{ marginLeft: '1em', cursor: 'pointer', textDecoration: 'underline' }} onClick={reset}>Reset</span></p> 
                    )}
                </Grid>
            </Grid>
            <Box mr={4} mt={3}>
                <Stack 
                    direction="column"
                    spacing={2}
                    divider={<Divider orientation="horizontal" />}
                    >
                    {hits.map((hit: any) => { 
                        return (
                            <InstructionBox
                                key={hit.document.id} 
                                data={hit.document} 
                            />
                        )
                    })}
                </Stack>
            </Box>
            {pages > 1 && (
                <Box textAlign="center" mt={5}>
                    <Stack alignItems="center">
                        <Pagination count={pages} variant="outlined" shape="rounded" onChange={handlePageChange}/>
                    </Stack>
                </Box>
            )}
        </Box>
    )

});