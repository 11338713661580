import CurrencyFormat from 'react-currency-format';

export const Money = ({
  value = 0, 
  basic = false, 
  decimal = 2, 
  currency = '',
}) => {
  
  let prefix = basic ? '' : '$';
  let suffix = basic ? '' : ` ${currency}`;

  switch (currency.toUpperCase()) {
    case 'GBP': 
      if (!basic) prefix = '£';
      suffix = '';
      break;
  }

  return (
    <CurrencyFormat
      displayType={'text'}
      decimalScale={decimal}
      fixedDecimalScale={true}
      value={value}
      thousandSeparator={true}
      suffix={suffix}
      prefix={prefix}
    />
  )
};