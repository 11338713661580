import {CompareBox} from './CompareBox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useAppContext } from 'hooks';

export const Compare = ({ 
    selections,
    onChange = () => {},
}:{ 
    selections: any[],
    onChange: (selections: any[]) => void,
}) => {

    const {matchesSmall} = useAppContext();

    const removeSelection = (product: Record<string, any>) => {
        const index = selections.findIndex((p: Record<string, any>) => p.id === product.id);
        if (index >= 0) {
          selections.splice(index, 1);
          onChange(selections);
        }
    }   

    const clearSelections = () => {
        onChange([]);
    }

    if (!selections?.length) return null;
    return (
        <Box mb={3}>
          <Divider sx={{margin:'20px 0 10px 0'}}/>
          <Box>
            <Button onClick={clearSelections}>Clear</Button>
          </Box>
          <Stack
            direction={matchesSmall ? "column" : "row"}
            spacing={1}
          >
            {selections.map((selection: Record<string, any>) => <CompareBox product={selection} onRemove={removeSelection}/>)}
          </Stack>
        </Box>
    )
}