import { withBaseLayout } from 'hoc';
import { Typography, Box, Container } from '@mui/material';
import { BoldItalic, P, AppName } from 'components';

export const FAQ = withBaseLayout(() => {

    return (
        <Container>
            <Box margin={3}>
                <Typography sx={{ typography: { xs: 'h5', md: 'h3' }}} mb={5}>
                    Frequently Asked Questions
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>What websites do you search?</BoldItalic>
                    <P>The list of websites we search is listed on our home page.</P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>How do you determine what websites to search?</BoldItalic>
                    <P>If the website sells pen/pencil kits, penmaking supplies, or even just turning tools to make pens, it's eligible.  We want to offer as much information about 
                        suppliers and the items offered to help the penmaking community find what they need. Most of the sites we search are from personal experience or suggestions by 
                        users of <AppName/>.
                    </P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>How long have you been indexing websites?</BoldItalic>
                    <P>We've been crawling some of our favorite sites since about mid January 2023.  We are adding new sites almost daily at the moment.  If you know a site you'd like indexed, please let us know!</P>
                </Typography>
                <Typography>
                    <BoldItalic>How often do you index websites?</BoldItalic>
                    <P>To keep listings fresh, we currently update about every 8 hours or so.</P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>A listing says a product is in stock, but on the website it's out of stock.</BoldItalic>
                    <P>
                        When indexing websites, we try to glean as much information as we can directly from the category pages to avoid having to visit each individual product page.
                    </P>
                    <P>
                        On some sites, we have to visit each page individually to get a complete listing.  Sometimes the stock status can change from the time we last indexed it.  Other sites 
                        do not provide stock status on the category page, but do list all of the other information.  We have a separate indexing process that visits individual pages at certain 
                        intervals to keep the listing as accurate as possible, so it may be that the individual page just hasn't been visited yet.
                    </P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>Why do the search results have items that are not pen/pencil kits?</BoldItalic>
                    <P>
                       We want to provide as much information about pen making supplies as well as pen/pencil kits so that our users can find whatever they need to complete a kit, find a 
                       new kit they haven't seen before, or even just find parts for an existing project.
                    </P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>Why are all the listings by the same supplier?</BoldItalic>
                    <P>
                       When we index sites, we randomize the order in which they are indexed in order to have a variety of suppliers show up on the home page.  Listings by default are 
                       sorted <BoldItalic>newest first,</BoldItalic> so the most recently updated listings are showing first.
                    </P>
                </Typography>
                <Typography paragraph={true}>
                    <BoldItalic>Can I exclude some results from the search?</BoldItalic>
                    <P>
                       Yes! When on the home page, please click the <BoldItalic>Search Tips</BoldItalic> link under the search bar to see how to adjust your search to get the exact results you want.
                    </P>
                </Typography>

            </Box>
        </Container>
    )   

})