import { useRef, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { 
    AppName,
    P,
    Bold,
    Italic,
    BoldItalic,
    Monospace,
} from 'components';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const SearchTipsModal = ({ open = false, onClose = () => {} }) => {

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);

    return (
        <Dialog
        open={open}
        onClose={onClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Search Tips</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <P>
                Welcome to <AppName/>!
            </P>
            <Typography variant={'h6'}>Keywords</Typography>
            <P>
                Our search engine uses Full-text searching with fuzzy matching (meaning that words that are similar will also be returned) so even if you're not sure how something is spelled, chances are we'll still find it.
            </P>
            <P>
                You can search one or more keywords (no commas needed) such as "pen" or "pen kit", and we'll return results that contain one or more of your keywords.  If you put the double-quotes around it we'll search for that exact phrase so all words will have to be matched.
            </P>
            <P>
                You can also tell the search engine what words you <BoldItalic>don't</BoldItalic> want the results to include by putting a minus symbol ("-") in front of a word. 
                For example, if you want to search for slimline kits but don't want to see tubes or bushings:
            </P>
            <P>
                <Monospace>slimline -tube -bushing</Monospace>
            </P>
            <P>
                However, when excluding words, the search engine is a bit more strict.  The above search will still include any results with "bushings" or "tubes", so you 
                have to exclude those as well:
            </P>
            <P>
                <Monospace>slimline -tube -tubes -bushing -bushings</Monospace>
            </P>
            <Typography variant={'h6'}>Sorting</Typography>
            You can sort results by different options:
            <List>
                <ListItem>
                    <ListItemText>Most Relevant - results are sorted by best match</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Most Recent (<Italic>default</Italic>) - results are sorted by newest listings</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Oldest</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Price (lowest/highest)</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Rating (lowest/highest) - note that not all sites use a rating system, so results from those sites will be ignored</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Name - results are sorted alphabetically by the product name</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>Supplier - results are sorted alphabetically by the supplier name</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText></ListItemText>
                </ListItem>
            </List>
            <P>
                <Bold>Please note:</Bold> When sorting results by an option such as "Most Recent" (anything other than price) and then using the secondary price 
                sort option, results are sorted FIRST by your first selection and in the case of a tie they are then sorted by your price selection.
            </P>
            <Typography variant={'h6'}>Filtering</Typography>
            <P>
                Currently results can be filtered by supplier.  More options coming soon!
            </P>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </Dialog>
    )
}