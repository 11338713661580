import { withBaseLayout } from 'hoc';
import { Link } from 'react-router-dom';
import { Typography, Box, Container } from '@mui/material';
import {ROUTES} from 'routes';

export const About = withBaseLayout(() => {

    return (
        <Container>
            <Box margin={3}>
                <Typography sx={{ typography: { xs: 'h5', md: 'h3' }}} mb={5}>
                    About PenKitSearch.com
                </Typography>
                <Typography paragraph={true}>
                    PenKitSearch.com is the result of a sleepless weekend by a web developer who has also been turning pens (and other things) for over 20 years.
                </Typography>
                <Typography paragraph={true}>
                    Tired of having to go to multiple sites to find products that were in-stock at the best price, he wondered "what if everything I was looking for was in one place and I could search and filter it how I needed?" 
                    Et voilà! PenKitSearch.com was born.
                </Typography>
                <Typography paragraph={true}>
                    We've been crawling some of our favorite sites since about mid January 2023.  There are still some kinks to work out and some functionality we'd like to implement to make the site even more useful.  Those will 
                    come in time...full-time job, family and life in general and all that...
                </Typography>
                <Typography>
                    Some of these updates include:
                    <ul>
                        <li>Save products to a list and optionally receive email updates</li>
                        <li>Stock notifications</li>
                    </ul>   
                </Typography>
                <Typography paragraph={true}>
                    If you have an idea or suggestion you'd like to share or a website you would like to see included, please <Link to={ROUTES.contact}>contact us</Link>.
                </Typography>
            </Box>
        </Container>
    )   

})