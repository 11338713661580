import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';

export const Footer = () => {

    const theme = useTheme();

    return (
        <Box mb={5} mt={5} sx={{width:'100%'}}>
            <Divider/>
            <Toolbar>
                <Grid 
                    container 
                    direction="row" 
                    columnSpacing={2} 
                    xs={12} 
                    mt={5} 
                    mb={5}
                    margin="none" 
                >
                    <Grid item xs={12} md={4} justifyContent="center" sx={{ textAlign: 'center' }}>
                        
                    </Grid>
                    <Grid item xs={12} md={4} justifyContent="center" sx={{ textAlign: 'center' }}>
                        &copy;{new Date().getFullYear()} PenKitSearch.com | {process.env.REACT_APP_VERSION}
                    </Grid>
                    <Grid item xs={12} md={4} justifyContent="center" sx={{ textAlign: 'center' }}>
                        
                    </Grid>
                </Grid>
            </Toolbar>
        </Box>
    )
}